<template>
  <div>
    <el-row type="flex" :gutter="40">
      <el-col
        :sm="24"
        :md="16"
        :lg="12"
        :xl="8"
      >
        <company-form-rooof
          ref="rooof"
          :form="formData"
        />
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col>
        <el-button
          id="submit-btn"
          :loading="loading"
          type="primary"
          @click="submit()"
        >
          Save
        </el-button>

        <el-button id="reset-btn" @click="reset()">
          Reset
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CompanyFormRooof from './CompanyFormRooof'

import { filterEmptyValues } from '@/utils'

export default {
  name: 'BaseCompanyForm',
  components: {
    'company-form-rooof': CompanyFormRooof
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dataModified () {
      // Sanitize fields
      const data = JSON.parse(JSON.stringify(this.formData))
      const dataCopy = JSON.parse(JSON.stringify(this.formCopy))

      for (const field in data) {
        if (Array.isArray(data[field])) {
          data[field] = filterEmptyValues(data[field])
          dataCopy[field] = filterEmptyValues(dataCopy[field])
        }
      }

      return JSON.stringify(data) !== JSON.stringify(dataCopy)
    }
  },
  watch: {
    formData: {
      handler () {
        this.formCopy = JSON.parse(JSON.stringify(this.formData))
      },
      immediate: true
    }
  },
  created () {
    window.addEventListener('beforeunload', this.handleUnload)
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.handleUnload)
  },
  methods: {
    /**
     * Reset all form fields using the builtin resetFields() method.
     */
    reset () {
      this.$refs['rooof'].$refs['company-form'].resetFields()
    },
    /**
     * Call builtin validate() method for each form.
     *
     * @returns {Boolean}
     */
    validate () {
      return new Promise(resolve => {
        this.$refs['rooof'].$refs['company-form'].validate(valid => {
          resolve(valid)
        })
      })
    },
    /**
     * onClick handler for submit button. Emits `save` event
     * which the parent can subscribe to for handling.
     */
    async submit () {
      const valid = await this.validate()
      if (valid) {
        this.$emit('save')
      }
    },
    /**
     * onbeforeunload handler, warns the user when they have have
     * modified data but not saved.
     *
     * @param {object} event
     * @returns {string|null} Message to display to user (ignored by all modern browsers), or null to cancel
     */
    handleUnload (event) {
      if (this.dataModified) {
        const msg = 'Data on this page may not be saved. Continue?'
        event.returnValue = msg
        return msg
      }
      return null
    }
  }
}
</script>
