<template>
  <div id="remote-multi-select">
    <el-select
      v-model="selected"
      :remote-method="fetchOptions"
      :loading="loading"
      :value-key="uniqueIdKey"
      placeholder="Type to search"
      class="multi-select"
      filterable
      multiple
      remote
    >
      <el-option
        v-for="option in options"
        :key="option[uniqueIdKey]"
        :label="option[labelKey]"
        :value="option"
      />
    </el-select>
  </div>
</template>

<script>
/**
 * RemoteMultiSelect.vue
 *
 * This component will render element-ui's select with the
 * remote and mutli options pre-set. When given an array of
 * primitive values as input, it will handle fetching these
 * objects from the api using the provided remote method.
 *
 * Some assumptions are required in order for this component
 * to work properly:
 *
 *   1) The `value` prop is only used to fetch the initial
 *      list of selected objects. Once that is complete,
 *      all selection state is controlled this component
 *      and the parent is notified via the `input` event.
 *
 *   2) `value-key` prop can be either a single string, or
 *      a list of strings. If a list is given, the emitted
 *      value will be an array of objects where every item
 *      in value-key is a key of that object. If a string is
 *      given, the emitted value will be an array of primitives
 *      where the value corresponds to object[valueKey].
 *
 *   3) The endpoint called in remoteMethod supports a query
 *      param filter with the same value as `filter-key` prop,
 *      if provided. If `filter-key` is not given, the uniqueIdKey
 *      computed property will be used instead.
 *
 *   4) `value-key` *must* be a unique parameter (if `value-key`
 *      is an array, the first value will be used to fetch the
 *      objects from the api. For example, if `value-key` is:
 *
 *      ['email', 'name']
 *
 *      and `value` is:
 *
 *      ['one@test.com', 'two@test.com', 'three@test.com']
 *
 *      A request will be made to the api with these parameters:
 *
 *      ?email=one@test.com&email=two@test.com&email=three@test.com
 *
 *   5) labelKey will be used to order the options list.
 */
import { getDistinctObjsByProp } from '@/utils'

export default {
  name: 'RemoteMultiSelect',
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    remoteMethod: {
      type: Function,
      required: true
    },
    valueKey: {
      type: [String, Array],
      required: true
    },
    labelKey: {
      type: String,
      required: true
    },
    filterKey: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      selected: [],
      searchResults: [],
      loading: false
    }
  },
  computed: {
    options () {
      const distinct = getDistinctObjsByProp(this.uniqueIdKey, this.selected, this.searchResults)
      return distinct.sort((a, b) => a[this.labelKey].localeCompare(b[this.labelKey]))
    },
    uniqueIdKey () {
      if (this.isObjectArray) {
        return this.valueKey[0]
      }
      return this.valueKey
    },
    isObjectArray () {
      return Array.isArray(this.valueKey)
    }
  },
  watch: {
    /**
     * Watch the list of selected objects for changes and
     * emit the new value list to the parent component.
     *
     * If value-key prop is an array, the value will be a
     * list of objects built using the elements of value-key
     * as the keys.
     */
    selected () {
      if (this.isObjectArray) {
        this.$emit('input', this.selected.map(obj => {
          return Object.fromEntries(this.valueKey.map(key => [key, obj[key]]))
        }))
      } else {
        this.$emit('input', this.selected.map(obj => obj[this.valueKey]))
      }
    },
    /**
     * Watch the value prop for changes. If the length of selected
     * items differs from the actual list of values, initiate the
     * prefetch operation again.
     *
     * This is required when `value` is changed in the parent
     * component (outside of this components `input` event), such
     * as when form data is fetched asynchronously.
     */
    value () {
      if (this.selected.length !== this.value.length) {
        this.prefetchData()
      }
    }
  },
  async created () {
    // Prefetch objects to be rendered in the select
    this.prefetchData()
  },
  methods: {
    /**
     * Pre-fetch a list of results from the api. Called
     * on initial create, as well as when the `value` prop
     * gets out of sync with `selected`.
     *
     * Will only fetch records which are present in `value`
     * but not in `selected`.
     */
    async prefetchData () {
      if (this.value.length) {
        const filterKey = this.filterKey ? this.filterKey : this.uniqueIdKey
        let recordsToFetch = this.value

        // Turn object array into list of primitives
        if (this.isObjectArray) {
          recordsToFetch = recordsToFetch.map(el => el[filterKey])
        }
        // Only look for records we haven't already fetched
        recordsToFetch = recordsToFetch.filter(value => {
          return this.selected.find(el => el[filterKey] === value) === undefined
        })

        if (recordsToFetch.length) {
          const params = new URLSearchParams()
          for (const value of recordsToFetch) {
            params.append(filterKey, value)
          }
          const newRecords = await this.fetchData(params)
          this.selected = this.selected.concat(newRecords)
        }
      }
    },
    /**
     * Fetch a list of filtered results from the API
     * using the provided remoteMethod.
     *
     * @param {String} searchValue
     */
    async fetchOptions (searchValue) {
      if (searchValue !== '') {
        const params = new URLSearchParams()
        params.append('search', searchValue)
        this.searchResults = await this.fetchData(params)
      }
    },
    /**
     * API request wrapper to call remote method
     * with the given paramaters.
     *
     * @param {Object} params
     * @returns {Array}
     */
    async fetchData (params) {
      try {
        this.loading = true
        const data = await this.remoteMethod(params)
        this.loading = false
        return data
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
#remote-multi-select {
  width: 100%;
}
.multi-select {
  width: 100%
}
</style>
