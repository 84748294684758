<template>
  <div id="add-contact-dialog">
    <el-dialog
      :visible="show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
      title="Create Contact"
      width="450px"
      @close="cancel"
    >
      <rooof-account-form
        ref="form"
        :loading="loading"
        :simple="true"
        @submit="createContact"
      />
    </el-dialog>
  </div>
</template>

<script>
import RooofAccountForm from '@/views/rooof-accounts/_components/RooofAccountForm'
import RooofAccountAPI from '@/services/api/accounts'

export default {
  name: 'AddContactDialog',
  components: {
    'rooof-account-form': RooofAccountForm
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    /**
     * Call API to createa RooofAccount object.
     *
     * @param {Object} data - rooof account data
     */
    async createContact (data) {
      try {
        this.loading = true
        const account = await RooofAccountAPI.users.create(data)
        const contact = {
          id: account.id,
          email: account.email,
          name: account.name
        }
        this.$emit('add-contact', contact)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
        this.$emit('close')
        this.reset()
      }
    },
    /**
     * Cancel event to be handled by the parent.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Reset form to default values.
     */
    reset () {
      this.$refs['form'].$refs['rooof-account-form'].resetFields()
      this.$refs['form'].$refs['rooof-account-form'].clearValidate()
    }
  }
}
</script>

<style>
#add-contact-dialog .el-dialog {
  width: 600px !important;
}
#add-contact-dialog .el-dialog__body {
  padding: 20px;
}
</style>
