<template>
  <div>
    <el-form
      ref="company-form"
      :model="form"
      :rules="rules"
      label-width="270px"
      label-position="left"
      size="medium"
    >
      <h3>General</h3>
      <el-form-item label="Human Name" prop="human_name">
        <el-input v-model="form.human_name" />
      </el-form-item>

      <el-form-item label="Name" prop="name">
        <el-input
          v-if="isUpdate"
          v-model="form.name"
        />
        <el-input
          v-else
          :value="formatName"
          disabled
        />
      </el-form-item>

      <el-form-item
        v-if="form.status"
        label="Status"
        prop="status"
      >
        <el-tag :type="getStatusTagType(form.status)" disable-transitions>
          {{ form.status }}
        </el-tag>
      </el-form-item>

      <el-form-item label="Start Date" prop="start_date">
        <el-date-picker
          v-model="form.start_date"
          type="date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          disabled
        />
      </el-form-item>

      <el-form-item label="End Date" prop="end_date">
        <el-date-picker
          v-model="form.end_date"
          type="date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          disabled
        />
      </el-form-item>

      <el-form-item
        label="Reason for Cancel"
        prop="reason_for_cancel"
      >
        <el-select v-model="form.reason_for_cancel">
          <el-option
            v-for="option in constants.reasonForCancel"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="Category"
        prop="category"
        class="is-required"
      >
        <el-select v-model="form.category">
          <el-option
            v-for="option in constants.companyCategory"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Email Domains" class="is-required">
        <list-input
          :value="form.email_domains"
          :rules="rules.email_domains"
          prop="email_domains"
        />
      </el-form-item>

      <h3>Account Management</h3>

      <el-form-item label="Account Manager" prop="account_manager">
        <el-select
          v-model="form.account_manager"
          :loading="loadingAccounts"
          value-key="id"
          placeholder="Select"
          filterable
        >
          <el-option
            v-for="option in rooofStaffAccounts"
            :key="option.id"
            :label="option.name"
            :value="option"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Decision Maker(s)" prop="decision_makers">
        <div class="inline">
          <remote-multi-select
            v-model="form.decision_makers"
            :remote-method="fetchRooofAccounts"
            :value-key="['id', 'email', 'name']"
            label-key="name"
          />
          <el-button @click="showAddDecisionMakerDialog = true">
            +
          </el-button>
        </div>
      </el-form-item>

      <el-form-item label="Emergency Contact(s)" prop="emergency_contacts">
        <div class="inline">
          <remote-multi-select
            v-model="form.emergency_contacts"
            :remote-method="fetchRooofAccounts"
            :value-key="['id', 'email', 'name']"
            label-key="name"
          />
          <el-button @click="showAddEmergencyDialog = true">
            +
          </el-button>
        </div>
      </el-form-item>

      <el-form-item label="JIRA Mapping URL" prop="jira_mapping_url">
        <el-input
          v-model.number="form.jira_mapping_url"
          type="url"
          placeholder="https://rooofdotcom.atlassian.net/browse/MAP-123"
        />
      </el-form-item>

      <h3>Sales</h3>

      <el-form-item label="Salesperson" prop="salesperson">
        <el-select
          v-model="form.salesperson"
          :loading="loadingAccounts"
          value-key="id"
          placeholder="Select"
          filterable
        >
          <el-option
            v-for="option in rooofStaffAccounts"
            :key="option.id"
            :label="option.name"
            :value="option"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Managed Properties" prop="managed_properties">
        <el-input-number
          v-model.number="form.managed_properties"
          :min="0"
        />
      </el-form-item>

      <el-form-item label="Sales Deals" class="is-required">
        <list-input
          :value="form.active_campaign_deals"
          :rules="rules.active_campaign_deals"
          prop="active_campaign_deals"
        />
      </el-form-item>

      <el-form-item
        label="Lead Source"
        prop="lead_source"
        class="is-required"
      >
        <el-select v-model="form.lead_source">
          <el-option
            v-for="option in constants.companyLeadSource"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="Organization Type"
        prop="organization_type"
      >
        <el-select v-model="form.organization_type">
          <el-option
            v-for="option in constants.organizationType"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <h3>Billing</h3>

      <el-form-item
        v-if="permissions.canViewAgreements||isStaff"
        label="Agreement"
        prop="agreement"
      >
        <file-upload
          file-types=".pdf"
          :files="agreements"
          :disable-change="!(permissions.canManageAgreements||isStaff)"
          @change="handleAgreementChange"
        />
      </el-form-item>

      <el-form-item
        v-if="permissions.canViewAgreements||isStaff"
        label="Agreement Type"
        prop="agreement_type"
      >
        <el-select v-model="form.agreement_type">
          <el-option
            v-for="option in constants.agreementTypes"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="Billing Frequency"
        prop="billing_frequency"
        class="is-required"
      >
        <el-select v-model.number="form.billing_frequency" placeholder="Select...">
          <el-option
            v-for="option in constants.billingFrequency"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Billing Method" prop="default_billing_method">
        <el-select v-model="form.default_billing_method" placeholder="Select...">
          <el-option
            v-for="option in constants.billingMethods"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Default Rate" prop="default_rate">
        <el-input
          v-model="form.default_rate"
          type="number"
          step=".01"
          placeholder="0.00"
        />
      </el-form-item>

      <el-form-item label="Currency" prop="currency_code">
        <el-select v-model="form.currency_code" placeholder="Select...">
          <el-option
            v-for="option in constants.currencies"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Billing Notes" prop="billing_notes">
        <el-input
          v-model="form.billing_notes"
          type="textarea"
          :rows="3"
        />
      </el-form-item>
      <div v-if="clientConfig">
        <el-form-item label="Unpaid Invoice Notifications" prop="clientConfig.enableFeatureDegradation">
          <el-select v-model="form.clientConfig.enableFeatureDegradation">
            <el-option
              v-for="option in constants.config"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>
      </div>

      <h3>Posting</h3>

      <el-form-item label="Policy" prop="policy">
        <el-input
          v-model="form.policy"
          :rows="3"
          type="textarea"
          placeholder="Corporate Craigslist policy"
        />
      </el-form-item>

      <el-form-item label="Disclaimer">
        <list-input
          textarea
          :value="form.disclaimer"
          :rules="rules.disclaimer"
          prop="disclaimer"
        />
      </el-form-item>

      <el-form-item label="Custom Fields" prop="extra">
        <el-input
          v-model="form.extra"
          type="textarea"
          :rows="3"
          placeholder="{}"
        />
      </el-form-item>

      <el-form-item label="Price Update Hour" prop="post_update_hour">
        <el-input-number
          v-model.number="form.post_update_hour"
          :min="1"
          :max="24"
          placeholder="12"
        />
      </el-form-item>
      <div v-if="clientConfig">
        <el-form-item label="Scheduled Posting in Chrome" prop="clientConfig.enableScheduledPostingInChrome">
          <el-select v-model="form.clientConfig.enableScheduledPostingInChrome">
            <el-option
              v-for="option in constants.config"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Editing & Deleting Live Ads" prop="clientConfig.allowEditingLiveAds">
          <el-select v-model="form.clientConfig.allowEditingLiveAds">
            <el-option
              v-for="option in constants.config"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Allow Self-destructing Ads" prop="clientConfig.allowSelfDestructingAds">
          <el-select v-model="form.clientConfig.allowSelfDestructingAds">
            <el-option
              v-for="option in constants.config"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Custom Titles in PostEngine Settings" prop="clientConfig.useCustomTitles_v6">
          <el-select v-model="form.clientConfig.useCustomTitles_v6">
            <el-option
              v-for="option in constants.showOrHide"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Exclude Offboarding Properties" prop="exclude_offboarding_properties">
          <el-select v-model="form.exclude_offboarding_properties">
            <el-option
              v-for="option in constants.config"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>
      </div>

      <el-form-item
        v-if="form.exclude_offboarding_properties"
        label="Exclude Offboarding Property Reasons"
        prop="exclude_offboarding_property_cancel_reasons"
      >
        <el-select
          v-model="form.exclude_offboarding_property_cancel_reasons"
          :multiple="true"
        >
          <el-option
            v-for="option in constants.reasonForCancel"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-form-item>

      <h3>Lead Tracking</h3>

      <el-form-item label="Short URL Domain" prop="short_url_domain">
        <el-input v-model="form.short_url_domain" />
      </el-form-item>

      <el-form-item label="Short URL Parameters" prop="short_url_params">
        <el-input v-model="form.short_url_params" />
      </el-form-item>

      <el-form-item label="Short URL Fallback Destination" prop="short_url_fallback_destination">
        <el-input v-model="form.short_url_fallback_destination" />
      </el-form-item>

      <el-form-item label="Emailhandler Lead Source" prop="emailhandler_lead_source">
        <el-input v-model="form.emailhandler_lead_source" />
      </el-form-item>

      <h3>Full Service</h3>

      <el-form-item label="Poster" prop="agency_poster">
        <el-select
          v-model="form.agency_poster"
          :loading="loadingPosterAccounts"
          filterable
        >
          <el-option
            label="Unassigned"
            :value="null"
          />
          <el-option
            v-for="poster in posterOptions"
            :key="poster.id"
            :label="poster.name"
            :value="poster.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Daily Posting Target" prop="daily_posting_target">
        <el-input-number
          v-model.number="form.daily_posting_target"
          :min="0"
        />
      </el-form-item>

      <div v-if="clientConfig">
        <el-form-item label="Full Service in Chrome" prop="clientConfig.enableAgencyDummyModeInChrome">
          <el-select v-model="form.clientConfig.enableAgencyDummyModeInChrome">
            <el-option
              v-for="option in constants.config"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Access to the Scheduled Posting Tab" prop="clientConfig.allowScheduledPostsTabAccessOverride">
          <el-select v-model="form.clientConfig.allowScheduledPostsTabAccessOverride">
            <el-option
              v-for="option in constants.config"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </el-form-item>
      </div>

      <!-- <photo-upload
        v-if="permissions.canViewPhotos||isStaff"
        :disable-change="!(permissions.canManagePhotos||isStaff)"
        :photos="photos"
        @change="handlePhotoChange"
      /> -->
    </el-form>

    <add-decision-maker-dialog
      :show="showAddDecisionMakerDialog"
      @add-contact="addDecisionMaker"
      @close="showAddDecisionMakerDialog = false"
    />

    <add-emergency-contact-dialog
      :show="showAddEmergencyDialog"
      @add-contact="addEmergencyContact"
      @close="showAddEmergencyDialog = false"
    />
  </div>
</template>

<script>
import ListInput from '@/components/forms/ListInput'
import RemoteMultiSelect from '@/components/forms/RemoteMultiSelect'
import FileUpload from '@/components/forms/FileUpload'
import AddContactDialog from '../dialogs/AddContactDialog'

import { constants } from '@/utils/constants'
import { validators } from '@/utils/element'
import { getNameFromHumanName, getStatusTagType } from '@/utils/rooof'

import CraigslistAPI from '@/services/api/craigslist'
import RooofAPI from '@/services/api/rooof'
import RooofAccountAPI from '@/services/api/accounts'

/**
 * Validation function which checks the given name against the api
 * to see if any objects already exist with that value.
 *
 * @param {String} fieldName - one of name, human_name
 * @param {String} originalValue - the original value of the field
 */
const uniqueNameValidator = async (fieldName, originalValue, value, callback) => {
  if (value === originalValue) {
    return callback()
  }
  try {
    const data = await RooofAPI.companies.list({ [fieldName]: value })

    if (data.length > 0) {
      return callback(new Error(`Company with this ${fieldName} already exists.`))
    }
  } catch (err) {
    return callback(new Error('Error fetching company from API.'))
  }
  return callback()
}

export default {
  name: 'CompanyFormRooof',
  components: {
    'list-input': ListInput,
    'remote-multi-select': RemoteMultiSelect,
    'add-decision-maker-dialog': AddContactDialog,
    'add-emergency-contact-dialog': AddContactDialog,
    'file-upload': FileUpload
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      clientConfig: null,
      permissions: {
        canViewAgreements: false,
        canManageAgreements: false,
        canViewPhotos: false,
        canManagePhotos: false
      },
      agreements: [],
      // Make copies of the original company name values
      originalName: this.form.name,
      originalHumanName: this.form.human_name,
      loadingAccounts: false,
      rooofStaffAccounts: [],
      loadingPosterAccounts: false,
      posterOptions: [],
      showAddDecisionMakerDialog: false,
      showAddEmergencyDialog: false,
      photos: [],
      mappings: [],
      rules: {
        name: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 128, message: 'Name cannot be more than 128 characters', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              return uniqueNameValidator('name', this.originalName, value, callback)
            },
            trigger: 'blur'
          }
        ],
        human_name: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { max: 128, message: 'Human Name cannot be more than 128 characters', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              return uniqueNameValidator('human_name', this.originalHumanName, value, callback)
            },
            trigger: 'blur'
          }
        ],
        billing_frequency: [
          { validator: validators.requireDefined, trigger: 'blur' }
        ],
        default_rate: [
          { validator: validators.positiveNumber, trigger: 'blur' }
        ],
        account_manager: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        salesperson: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        email_domains: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { validator: validators.emailDomain, trigger: 'blur' }
        ],
        managed_properties: [
          { validator: validators.positiveInteger, trigger: 'blur' }
        ],
        daily_posting_target: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { validator: validators.positiveInteger, trigger: 'blur' }
        ],
        jira_mapping_url: [
          { type: 'url', message: 'Please provide a valid URL', trigger: 'blur' }
        ],
        category: [
          { validator: validators.requireDefined, trigger: 'blur' }
        ],
        lead_source: [
          { validator: validators.requireDefined, trigger: 'blur' }
        ],
        extra: [
          { validator: validators.json, trigger: 'blur' }
        ],
        disclaimer: [
          { max: 400, message: 'Disclaimer cannot be more than 400 characters', trigger: 'blur' }
        ],
        short_url_params: [
          { validator: validators.trackingParameterValidator, message: 'Tracking parameters are invalid, must begin with either "/" or "?"', trigger: 'blur' }
        ],
        active_campaign_deals: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          { type: 'url', message: 'Please provide a valid URL', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    companies () {
      return this.$store.state.companies.data
    },
    isUpdate () {
      return this.$route.name === 'CompanyEdit'
    },
    formatName () {
      return getNameFromHumanName(this.form.human_name)
    },
    /**
     * Determine if the current user is staff.
     *
     * @returns {Boolean}
     */
    isStaff () {
      return this.$store.getters['auth/isStaff']
    }
  },
  watch: {
    /**
     * If we're creating a new company, set the name
     * as the user types the human name
     */
    formatName () {
      if (!this.isUpdate) {
        this.form.name = this.formatName
      }
    }
  },
  async created () {
    this.getStatusTagType = getStatusTagType
    this.constants = constants
    this.fetchStaffAccounts()
    if (this.form.id) {
      await this.fetchPermissions()
      if (this.permissions.canViewAgreements || this.isStaff) {
        this.loadAgreements()
      }
      await this.fetchClientConfig()
      await this.fetchPosterAccounts()
      // if (this.permissions.canViewPhotos || this.isStaff) {
      //   this.loadPhotos()
      // }
    }
  },
  methods: {
    /**
     * Add a decision maker to array of
     * decision makers for the property
     */
    addDecisionMaker (account) {
      this.form.decision_makers.push(account)
    },
    /**
     * Add an emergency contact to array of
     * emergency contacts for the property
     */
    addEmergencyContact (account) {
      this.form.emergency_contacts.push(account)
    },
    /**
     * Fetch a list of staff rooof accounts from the api.
     */
    async fetchStaffAccounts () {
      this.loadingAccounts = true
      try {
        const params = {
          is_staff: true,
          page_size: 50
        }
        const response = await RooofAccountAPI.users.listPage(params)
        this.rooofStaffAccounts = response.results.sort((a, b) => a.name.localeCompare(b.name))
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
      this.loadingAccounts = false
    },
    /**
     * Fetch a list of rooof accounts from the database, restricted
     * to active and non-staff accounts.
     *
     * @param {Object} params - URLSearchParams object
     */
    async fetchRooofAccounts (params) {
      params.set('page_size', 50)
      params.set('is_active', true)
      params.set('is_staff', false)
      const response = await RooofAccountAPI.users.listPage(params)
      return response.results
    },
    /**
     * Fetch poster accounts to select from
     */
    async fetchPosterAccounts () {
      try {
        this.loadingPosterAccounts = true
        this.posterOptions = await RooofAccountAPI.users.list({ is_poster: true })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loadingPosterAccounts = false
      }
    },
    /**
    * Update added and removed agreement lists to reflect changes.
    */
    handleAgreementChange (changes) {
      this.form.agreements = {
        added: changes.added,
        removed: changes.removed
      }
    },
    /**
    * Update added and removed photo lists to reflect changes.
    */
    handlePhotoChange (changes) {
      this.form.photos.added = changes.added
      this.form.photos.removed = changes.removed
    },
    /**
     * Load user permissions from the API
     */
    async fetchPermissions () {
      try {
        const permissions = await RooofAPI.companies.permissionsList(this.form.id)
        this.permissions.canViewPhotos = permissions.includes('view_company_syndication_photos')
        this.permissions.canManagePhotos = permissions.includes('manage_company_syndication_photos')
        this.permissions.canViewAgreements = permissions.includes('view_company_agreements')
        this.permissions.canManageAgreements = permissions.includes('manage_company_agreements')
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Load agreements from API.
     */
    async loadAgreements () {
      try {
        this.agreements = await RooofAPI.agreements.list(this.form.id, 'companies')
        this.$set(this.form, 'agreements', { added: [], removed: [] })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
    * Load syndication photos from API.
    */
    async loadPhotos () {
      try {
        this.photos = await CraigslistAPI.syndication_photos.list(this.form.id, 'groups')
        this.$set(this.form, 'photos', { added: [], removed: [] })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Fetch company client configuration
    */
    async fetchClientConfig () {
      try {
        const response = await RooofAPI.config.retrieve(this.$route.params.cid)
        this.clientConfig = response
        this.$set(this.form, 'clientConfig', this.clientConfig)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inline {
  display: flex;
  justify-content: space-between;

  .el-button {
    margin-left: 10px;
  }
}
</style>
