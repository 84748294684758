<template>
  <div id="list-input">
    <div
      v-for="(item, index) in value"
      :key="index"
      class="row"
    >
      <el-form-item
        :prop="`${prop}.${index}`"
        :rules="rules"
      >
        <el-input
          v-model="value[index]"
          :disabled="disabled"
          :type="textarea ? 'textarea':'text'"
          autosize
        />
      </el-form-item>

      <div
        v-if="!disabled"
        class="add-remove-buttons"
      >
        <i
          class="icon el-icon-circle-plus"
          @click="addItem(index + 1)"
        />
        <i
          v-if="index > 0"
          class="icon el-icon-remove"
          @click="removeItem(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListInput',
  props: {
    prop: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    textarea: {
      type: Boolean,
      default: false
    }
  },
  created () {
    // Validation rules will only work if the `prop` property is set
    if (this.rules.length && !this.prop) {
      console.warn('ListInput: `rules` prop was passed to component without an associated `prop`')
    }

    // Render at least one item
    if (!this.value.length) {
      this.addItem(0)
    }
  },
  updated () {
    if (!this.value.length) {
      this.addItem(0)
    }
  },
  methods: {
    /**
     * Inserts a new item to the list at index.
     *
     * @param {Number} index
     */
    addItem (index) {
      this.value.splice(index, 0, '')
    },
    /**
     * Removes the item at the given index.
     *
     * @param {Number} index
     */
    removeItem (index) {
      this.value.splice(index, 1)
    }
  }
}
</script>

<style>
#list-input .el-form-item {
  width: 100%;
  margin-bottom: 15px;
}
</style>

<style lang='scss' scoped>
.row:not(:first-child) {
  margin-top: 5px;
}
.row {
  display: flex;

  .add-remove-buttons {
    margin-left: 8px;
    min-width: 50px;
    width: 50px;

    .icon {
      cursor: pointer;
      font-size: 18px;
    }

    .el-icon-circle-plus {
      color: #67c23a;
      margin-right: 5px;
    }

    .el-icon-remove {
      color: #f56c6c;
    }
  }
}

</style>
