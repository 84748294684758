<template>
  <div class="file-upload">
    <el-upload
      action=""
      :disabled="disableChange"
      :accept="fileTypes"
      :auto-upload="false"
      :limit="15"
      :file-list="files"
      :on-change="handleChange"
      :on-remove="handleChange"
      :on-preview="handlePreview"
    >
      <el-button
        size="small"
        type="primary"
        :disabled="disableChange"
        :loading="loading"
      >
        Click to upload
      </el-button>
      <div slot="tip" class="el-upload__tip">
        {{ `Files with a size less than ${formattedBytes}.` }}
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <iframe
        v-if="mimeType === 'application/pdf'"
        width="100%"
        :src="fileUrl"
        :type="mimeType"
      />
      <img
        v-else
        width="100%"
        :src="fileUrl"
        alt=""
      >
    </el-dialog>
  </div>
</template>

<script>
import { formatBytes, downloadFile } from '@/utils'

export default {
  name: 'FileUpload',
  props: {
    fileTypes: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 1024 * 1024 * 10
    },
    files: {
      type: Array,
      default: () => []
    },
    disableChange: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileUrl: '',
      mimeType: '',
      dialogVisible: false
    }
  },
  computed: {
    formattedBytes () {
      return formatBytes(this.maxSize)
    }
  },
  methods: {
    /**
     * onChange handler for file upload input.
     *
     * @param {Object} file - uploaded file object
     * @param {Array} fileList - list of uploaded files
     */
    handleChange (file, fileList) {
      // Only validate added files
      const index = fileList.indexOf(file)
      if (index !== -1 && file.status === 'ready' && !this.validate(file)) {
        this.handleRemove(index, fileList)
        return
      }
      this.$emit('change', {
        added: fileList.filter(x => !this.files.find(y => y.uid === x.uid)),
        removed: this.files.filter(x => !fileList.find(y => y.uid === x.uid))
      })
    },
    /**
     * Remove invalid files from fileList
     *
     * @param {Number} index - index of file to remove
     * @param {Array} fileList - list of uploaded files
     */
    handleRemove (index, fileList) {
      fileList.splice(index, 1)
    },
    /**
     * Validation for changed file upload input.
     *
     * @param {Object} file - uploaded file object
     */
    validate (file) {
      const isLessThanMaxSize = file.size <= this.maxSize
      if (!isLessThanMaxSize) {
        this.$message.error(`File size can not exceed ${this.formattedBytes}.`)
      }

      return isLessThanMaxSize
    },
    /**
     * Determine if a file was uploaded or returned by the API
     *
     * @param {Object} file - file object
     */
    isApiResource (file) {
      return file.id
    },
    /**
     * onPreview handler for file upload input.
     *
     * @param {Object} file - file object
     */
    handlePreview (file) {
      if (this.isApiResource(file)) {
        if (file.mimeType.startsWith('image/') || file.mimeType === 'application/pdf') {
          // Preview Images and PDFs
          this.mimeType = file.mimeType
          this.fileUrl = file.url
          this.dialogVisible = true
        } else {
          // Download other file types
          const extPosition = file.name.lastIndexOf('.')
          const [name, ext] = [file.name.substring(0, extPosition), file.name.substring(extPosition + 1)]
          downloadFile(file.url, name, ext, file.mimeType)
        }
      }
    }
  }
}
</script>

<style>
.file-upload .el-dialog__body iframe {
  height: 100vh;
}
.file-upload .el-upload-list__item:focus {
  outline: none;
}
.file-upload .el-upload-list__item a {
  text-decoration: underline;
  cursor: pointer;
}
.file-upload .is-ready a {
  text-decoration: none;
  cursor: default;
}
</style>
